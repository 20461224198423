import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { locale, loadMessages, formatMessage } from 'devextreme/localization';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import countryList from 'react-select-country-list';
import DataSource from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';


import TabPanel, { Item } from "devextreme-react/tab-panel";

import Box from 'devextreme-react/box';

import TextArea from 'devextreme-react/text-area';


import ArrayStore from 'devextreme/data/array_store';

import { getCalculationModels } from '../../contexts/models';

import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  FilterPanel,
  FilterBuilderPopup,
  Scrolling,
  Grouping,
  Lookup,
  MasterDetail,
  Format,
  Editing,
  Selection,
  Button,
  Sorting
} from 'devextreme-react/data-grid';

import SelectBox from 'devextreme-react/select-box';


import CustomStore from 'devextreme/data/custom_store';

import Form, {
  ButtonItem,
  GroupItem,
  SimpleItem,
  Label,
  CompareRule,
  EmailRule,
  PatternRule,
  RangeRule,
  RequiredRule,
  StringLengthRule,
  AsyncRule,
  CustomRule,
  FormTypes
} from 'devextreme-react/form';

import MasterQuotationItemLookup from '../../components/lookups/QuotationItemLookup';

import ItemModelForm from '../../components/item-model-form/ItemModelForm';
import ItemModelButtons from '../../components/item-model-form/itemModelButtons';


import './quotations.scss';



const stockSign = (cell) => {

  let stockClass = 'stockGreen';
  if (cell.text == 0) stockClass = 'stockOrange';
  if (cell.text == -1) stockClass = 'stockRed';
  if (cell.text == 2) stockClass = 'stockGrey';
  return(
    <div className={stockClass}>
      <span>{cell.text}</span>
    </div>
  );
};



let defaultFinish = 'Keine';
 
const finishEditorOptions = { 
  items:[
    'Keine', 'Pulverbeschichtet', 'Eloxal', 'Sonstiges (siehe Information)'
  ], 
  value: defaultFinish,
};

const QuotationNew = () => {

  const dataGrid = useRef(null);
  const dataGridNewQuotation = useRef(null);
  const quantityUnitData = [
    {Key: 'BESTAND_IN_KG', Name: formatMessage('BESTAND_IN_KG')}, 
    {Key: 'BESTAND_IN_STG', Name: formatMessage('BESTAND_IN_STG')}, 
    {Key: 'VERFUEGBARE_MENGE', Name: formatMessage('VERFUEGBARE_MENGE')}
  ];

  const NewQuotationRef = useRef(null);
  const [data, setData] = useState(null);
  const [dataStore, setDataStore] = useState({});

  const [alloyStore, setAlloyStore] = useState({});

  const [itemModelStore, setItemModelStore] = useState({});

  const [loading, setLoading] = useState(true);

  const [currentIoColumns, setSurrentIoColumns] = useState([]);

  const [itemQuery, setState] = React.useState({
    ItemModelId: 1,
    Measurements: '',
    ItemModelId: 12,
    A: null,
    B: null,
    C: null,
    D: null,
    S1: null,
    S2: null,
    S3: null,
    R1: null,
    R2: null,
    R3: null,
    R4: null,
    R5: null,
    R6: null,
    Quantity: 0,
    QuantityUnit: 'BESTAND_IN_KG'
  });

  const StockHeaderFilters = [
    {
      text: formatMessage('InStock'),
      value: ['Availability', '=', '1'],
    },
    {
      text: formatMessage('OnRequest'),
      value: ['Availability', '=', '2'],
    }
  ];

  const countries = useMemo(() => countryList().getData(), []);
  function setCountry (evt){
    NewQuotation.Country = evt.value;
    localStorage.setItem('NewQuotation', JSON.stringify(NewQuotation));
  }


  let NewQuotation = JSON.parse(localStorage.getItem('NewQuotation'));
  if (!NewQuotation){
    NewQuotation = {};
    NewQuotation.Country = 'AT';
    NewQuotation.QuotationItems = [];
    localStorage.setItem('NewQuotation', JSON.stringify(NewQuotation));
  }

  
  //let NewQuotationItemsDataStore = NewQuotation.QuotationItems;

  const [NewQuotationItemsDataStore, setNewQuotationItemsDataStore] = useState(NewQuotation.QuotationItems);
  const [NewQuotationItemscounter, setNewQuotationItemsCounter] = useState(NewQuotation.QuotationItems.length);

  function tabPanelSelectionChanged (e) {
    NewQuotation = JSON.parse(localStorage.getItem('NewQuotation'));
    setNewQuotationItemsDataStore(NewQuotation.QuotationItems);
  }

  function formFieldDataChanged(e) {
    const updatedField = e.dataField;
    const newValue = e.value;
    if (e.dataField == "ItemModelId") {
      //itemQuery.ItemModelId = false;
      itemQuery.Measurements = '';
    }
    // Event handling commands go here
    setState({
      ...itemQuery,
      [e.dataField]: e.value
    });
  }

  const alloysEditorOptions = { 
    items: alloyStore,
    searchEnabled: false,
    showClearButton: true
  };


    useEffect(() => {

      const localStore = localStorage.setItem;
      localStorage.setItem = function(key, value) {
        const event = new Event('localUpdated');
              event.key = key; 
              event.value = value; 
    
        if (event.key === 'NewQuotation'){
          let currentQuotation = JSON.parse(event.value);
          console.log('setNewQuotationItemsCounter:', currentQuotation.QuotationItems.length);
          setNewQuotationItemsCounter(currentQuotation.QuotationItems.length);
        }
        localStore.apply(this, arguments);
      };

      async function fetchAlloys() {
        const responseAlloys = await fetch('/api/GetAlloySelection');
        const jsonAlloys = await responseAlloys.json();

        let alloyList = [];
        jsonAlloys.GetAlloySelectionResult.List.forEach(alloy => {
          alloyList.push(alloy.AlloyId);
        });

        setAlloyStore(alloyList);

      }
      fetchAlloys();

      async function fetchItemModels() {
        const responseItemModels = await fetch('/api/GetItemModelList');
        const jsonItemModels = await responseItemModels.json();

        jsonItemModels.GetItemModelListResult.List.forEach(itemModel => {
          itemModel.DisplayName = formatMessage(itemModel.ModelName);
          let visibleIoColumns = [];
          itemModel.IoColumns.List.forEach(IoColumn => {
            if (IoColumn.Visible === 1){
              visibleIoColumns.push(IoColumn);

              if (IoColumn.ColumnName == "GOG") {
                IoColumn.ColumnDescription = formatMessage(IoColumn.ColumnName);
              }

            }
          });
          itemModel.IoColumns.List = visibleIoColumns;
        });
        setItemModelStore(jsonItemModels.GetItemModelListResult.List);
        setLoading(false);
      }
      fetchItemModels();

      let allCoordinates = [
        'A',
        'B',
        'C',
        'D',
        'S1',
        'S2',
        'S3',
        'R1',
        'R2',
        'R3',
        'R4',
        'R5',
        'R6',
      ];

      let coordinateValuesCount = 0;
      allCoordinates.forEach(coordinate => {
        if (itemQuery[coordinate] != null) coordinateValuesCount++;
      });

      if (coordinateValuesCount > 0){

        if (itemQuery.QuantityUnit === "") itemQuery.QuantityUnit = "BESTAND_IN_KG";

        const url = '/api/GetItemsByQueryForInternet';
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(itemQuery)
        };


        async function fetchData() {
          const response = await fetch(url, requestOptions);
          const json = await response.json();

          json.List.forEach(item => {
            item.ArticleName = formatMessage(item.ItemModelName);
            if (item.OracleArticle) {
              item.Catalog = (item.OracleArticle.KATALOG == 'J') ? true : false;
              
              // if (item.OracleArticle.KATALOG != 'J') {
              //   item.Availability = -1;
              // }
            }
            else {
              item.Catalog = false;
              //item.Availability = -1;

            }

            if (item.Availability === 1) {
              item.Availability = 1;
            }
            else if (item.Availability !== 1 && item.Catalog === true) {
              item.Availability = 1;
            }
            else {
              item.Availability = 2;
            }



            item.Measurements = '';
            item.ItemModelIoColumnList.List.forEach(coordinate => {
              item.Measurements += coordinate.ColumnDescription + ':' + item[coordinate.ColumnName].toFixed(2).toString() + ' ';
            });

            if (item.GogAffected) {
              item.GOG = formatMessage('GOG_'+item.GOG);
            }
            else {
              item.GOG = 'N/A';
            }

          });
          
          // setDataStore(new CustomStore({
          //     key: 'ID_SQL',
          //     load: () => {
          //       return json.List
          //     }
          //   }
          // ));

          setDataStore(new DataSource({
            store: new CustomStore({
                key: 'ID_SQL',
                load: () => {
                  return json.List
                }
              }
            ),
            sort: [
              'Availability',
              { selector: 'Availability', desc: true }
          ],
          }));



          setData(json.List);
        }
        fetchData();

        let currentItemModelStore = itemModelStore.find((itemModel) => itemModel.ID_SQL === parseInt(itemQuery.ItemModelId));
        setSurrentIoColumns(currentItemModelStore.IoColumns.List);
      }


    }, [itemQuery]);


    const forwardQuotationButtonOptions = {
      text: "Weiterleiten",
      type: 'primary',
      width: 200,
      //icon: 'fa-sharp fa-solid fa-check',
      useSubmitBehavior: false,
      onClick: function(e) {
        
      }
    };
  
    const completeQuotationButtonOptions = {
      text: "Abschicken",
      type: 'default',
      width: 200,
      //icon: 'fa-sharp fa-solid fa-book-open',
      useSubmitBehavior: false,
      onClick: function(e) {
        e.event.preventDefault();
        let validation = e.validationGroup.validate();
        if (validation.isValid) {
          console.log(NewQuotation);
          postNewQuotation();
        }
      }   
    }

    function postNewQuotation () {
      const postOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      };
      
      NewQuotation.ExternalSource = 1; 

      postOptions.body = JSON.stringify(NewQuotation);
      async function PostQuotation() {
        const response = await fetch('/api/PostQuotation', postOptions);
        const json = await response.json();
        window.location.replace('/vielen-dank-fuer-ihre-anfrage/');
      }
      PostQuotation();
      localStorage.removeItem('NewQuotation');
    }

    const [allMode, setAllMode] = useState('allPages');
    const [checkBoxesMode, setCheckBoxesMode] = useState(

    );
    const onCheckBoxesModeChanged = useCallback(({ value }) => {
      setCheckBoxesMode(value);
    }, []);
    const onAllModeChanged = useCallback(({ value }) => {
      setAllMode(value);
    }, []);

    const salutationEditorOptions = { items:['Frau', 'Herr', 'Divers'], searchEnabled: false };

//


// useEffect(() => {
//   const localStore = localStorage.setItem;
//   localStorage.setItem = function(key, value) {
//     const event = new Event('localUpdated');
//           event.key = key; 
//           event.value = value; 

//     console.log(event.key);
//     if (event.key === 'NewQuotation'){
//       let currentQuotation = JSON.parse(event.value);
//       setNewQuotationItemsCounter(currentQuotation.QuotationItems.length);
//     }
//     localStore.apply(this, arguments);
//   };
// }, []);

function deleteItem (e){
  if (e.changes[0].type == "remove"){
    let delete_ID_SQL = e.changes[0].key.ID_SQL;
    let currentNewQuotation = JSON.parse(localStorage.getItem('NewQuotation'));
    currentNewQuotation.QuotationItems.forEach((item, key) => {
      if (item.ID_SQL === delete_ID_SQL) {
        currentNewQuotation.QuotationItems.splice(key, 1);
      }
    });
    localStorage.setItem('NewQuotation', JSON.stringify(currentNewQuotation));
    setNewQuotationItemsCounter(currentNewQuotation.QuotationItems.length);
  }
}

  function NewQuotationDataChanged (e){
    localStorage.setItem('NewQuotation', JSON.stringify(NewQuotation));
  }

  function coordinateInputChange (evt) {
    let value = evt.target.value;
    if (value === '' || value == 0) value = null;
    setState({
      ...itemQuery,
      [evt.target.name]: value
    });
  }

  function setQuantityUnit (evt) {
    setState({
      ...itemQuery,
      ['QuantityUnit']: evt.value
    });
  }

  function changeItemModelSelection (evt) {
    let elements = document.getElementsByClassName('itemModelButton');
    Array.from(elements).forEach(element => {
      element.classList.remove('active');
    });

    evt.currentTarget.classList.toggle('active');
    const value = evt.currentTarget.value;
    setState({
      ...itemQuery,
      ['ItemModelId']: value,
      ['A']: null,
      ['B']: null,
      ['C']: null,
      ['D']: null,
      ['S1']: null,
      ['S2']: null,
      ['S3']: null,
      ['R1']: null,
      ['R2']: null,
      ['R3']: null,
      ['R4']: null,
      ['R5']: null,
      ['R6']: null,
      ['Quantity']: 0,
      ['QuantityUnit']: ''
    });
    setDataStore({});
    setData({});
    dataGrid.current.instance.clearFilter();
  }

  const cellTemplateQuantityUnit = (container, options) => {
    container.textContent = formatMessage(options.value);
  };

  const toggleMasterRow = useCallback((event) => {
    if (event.columnIndex > 0) {
      if (dataGrid.current.instance.isRowExpanded(event.key)) {
          dataGrid.current.instance.collapseRow(event.key);
      } else {
          dataGrid.current.instance.expandRow(event.key);
      }
    }
  }, []);

  const toggleMasterRowSummary = useCallback((event) => {
    if (event.columnIndex > 0) {
      if (dataGridNewQuotation.current.instance.isRowExpanded(event.key)) {
        dataGridNewQuotation.current.instance.collapseRow(event.key);
      } else {
        dataGridNewQuotation.current.instance.expandRow(event.key);
      }
    }
  }, []);

  const [popupVisible, setPopupVisible] = useState(false);
  const hide = useCallback(() => {
    setPopupVisible(false);
  }, [setPopupVisible]);
  const showPopup = useCallback(() => {
    setPopupVisible(true);
  }, [setPopupVisible]);

  const forwardToCheckout = {
    text: "Anfrage abschicken",
    type: 'default',
    width: 200,
    //icon: 'fa-sharp fa-solid fa-book-open',
    useSubmitBehavior: false,
    disabled: ((NewQuotationItemscounter > 0) ? false : true),
    onClick: function(e) {
      setPopupVisible(true);
    }
  }

  function addToQuotationButtonOptions (e) {
    if (!NewQuotation.QuotationItems)  NewQuotation.QuotationItems = [];
    let currentItem = e.row.data;

    if (itemQuery.Quantity === 0) {
      notify(
        {
          message: formatMessage('noQuantity'),
          position: {
            my: 'center center',
            at: 'center center',
          },
        },
        'warning',
        3000,
      );
      return false;
    }

    currentItem.Quantity = itemQuery.Quantity;
    currentItem.QuantityUnit = itemQuery.QuantityUnit;
    currentItem.Info = itemQuery.Info;
    currentItem.Finish = itemQuery.Finish;
    currentItem.Cut = itemQuery.Cut;
  
    NewQuotation.QuotationItems.push(currentItem)
    localStorage.setItem('NewQuotation', JSON.stringify(NewQuotation));

    setNewQuotationItemsCounter(NewQuotation.QuotationItems.length);

    notify(
      {
        message: formatMessage('addSuccess'),
        position: {
          my: 'center center',
          at: 'center center',
        },
      },
      'success',
      3000,
    );
  }


  return (
    <div className="App">
      {loading ? (
        <div>Loading...</div>
      ) : (

        <div className={'content-block'}>
<Popup
  width={'auto'}
  height={'auto'}
  visible={popupVisible}
  onHiding={hide}
  hideOnOutsideClick={true}
  showCloseButton={true}
  title=""
>
<form
                  action=""
                  id=""
                  method="post"
                >
              <Form
                ref={NewQuotationRef}
                formData={NewQuotation}
                readOnly={false}
                colCount={1}
                showColonAfterLabel={true}
                showValidationSummary={true}
                validationGroup="customerData"
                onFieldDataChanged={NewQuotationDataChanged}
              >

                <GroupItem colCount={1}>
                  <GroupItem>
                    <GroupItem caption={formatMessage('Contact')}>
                      <GroupItem colCount={2}>
                        <Item dataField="Salutation" editorType="dxSelectBox" editorOptions={salutationEditorOptions}>
                          <Label text={formatMessage('Salutation')}  />
                        </Item>
                        <SimpleItem dataField="Title" editorType="dxTextBox" >
                            <Label text={formatMessage('Title')} />
                        </SimpleItem>
                      </GroupItem>
                      <GroupItem colCount={2}>
                        <SimpleItem dataField="Firstname" editorType="dxTextBox" >
                            <Label text={formatMessage('Firstname')}  />
                            <RequiredRule message="Firstname is required" />
                        </SimpleItem>
                        <SimpleItem dataField="Lastname" editorType="dxTextBox" >
                            <Label text={formatMessage('Surname')}  />
                            <RequiredRule message="Lastname is required" />
                        </SimpleItem>
                      </GroupItem>
                      <GroupItem colCount={2}>
                        <SimpleItem dataField="Phone" editorType="dxTextBox" >
                            <Label text={formatMessage('Phone')}  />
                            <RequiredRule message="Phone is required" />
                        </SimpleItem>
                        <SimpleItem dataField="EMail" editorType="dxTextBox" >
                            <Label text={formatMessage('Email')}  />
                            <RequiredRule message="E-Mail is required" />
                        </SimpleItem>
                      </GroupItem>
                      <GroupItem>
                        <Item
                        dataField="NewInformation"
                        editorType="dxTextBox"
                        >
                          <Label text={formatMessage('Information')} />
                        </Item>
                      </GroupItem>
                    </GroupItem>
                  </GroupItem>

                  <GroupItem caption='&nbsp;'>
                      <GroupItem colCount={2}>
                        <SimpleItem dataField="Company" colSpan={2} editorType="dxTextBox" >
                            <Label text={formatMessage('Company')}  />
                            <RequiredRule message="Company is required" />
                        </SimpleItem>
                        <SimpleItem dataField="UID" editorType="dxTextBox" >
                            <Label text={formatMessage('VAT')} />
                        </SimpleItem>
                      </GroupItem>
                      <GroupItem colCount={2}>
                        <SimpleItem colSpan={2} dataField="Address1" editorType="dxTextBox" >
                            <Label text={formatMessage('Street')} />
                            <RequiredRule message="Street is required" />
                        </SimpleItem>
                        <SimpleItem dataField="PostCode" editorType="dxNumberBox" >
                            <Label text={formatMessage('PostCode')}  />
                            <RequiredRule message="PostCode is required" />
                        </SimpleItem>
                        <SimpleItem dataField="City" editorType="dxTextBox" >
                            <Label text={formatMessage('City')}  />
                            <RequiredRule message="City is required" />
                        </SimpleItem>
                      </GroupItem>
                      <GroupItem colCount={2}>
                        <Item colSpan={2} >
                          <SelectBox
                              dataField='Country'
                              dataSource={countries}
                              displayExpr="label"
                              valueExpr="value"
                              defaultValue={'AT'}
                              onValueChanged={setCountry}
                          />
                              <Label text={formatMessage('Country')}  />
                              <RequiredRule message="Country is required" />
                        </Item>
                      </GroupItem>
                  </GroupItem>
                </GroupItem>
                <ButtonItem buttonOptions={completeQuotationButtonOptions} />
                </Form>
              </form>
</Popup>

            
              <form
                  action=""
                  id=""
                  method="post"
                >
              <Form
                formData={{}}
                readOnly={false}
                colCount={1}
                showColonAfterLabel={true}
                showValidationSummary={true}
              >

                  <GroupItem cssClass="buttons-group" colCount={1}>
                    <ButtonItem buttonOptions={forwardToCheckout}  />
                  </GroupItem>
                <hr />

                </Form>
                </form>


            <TabPanel animationEnabled={true} className={'new-quotation-tabpanel'} swipeEnabled={true} onSelectionChanged={tabPanelSelectionChanged} iconPosition="start">
              <Item title={formatMessage('ItemSearch')} icon="search" className={'new-quotation-tabpanel-search'}>
                  <div style={{}}>
                    <div className={'quotation-itemmodel-selection'}>
                      <ItemModelButtons itemQuery={itemQuery} itemModelStore={itemModelStore} changeItemModelSelection={changeItemModelSelection} ></ItemModelButtons>
                    </div>
                    <div className={'quotation-search-content'}>
                  <Form
                    formData={itemQuery}
                    readOnly={false}
                    colCount={1}
                    showColonAfterLabel={true}
                    showValidationSummary={true}
                    validationGroup=""
                    onFieldDataChanged={formFieldDataChanged}
                  >
                    <GroupItem colCount={4}>
                        <Item colSpan={1}>
                          <ItemModelForm itemQuery={itemQuery} coordinateInputChange={coordinateInputChange} />
                      </Item>
                      <GroupItem colSpan={3} colCount={3}>
                        <SimpleItem
                          dataField='Quantity'
                          editorType="dxTextBox"
                        >
                          <Label text={formatMessage('Quantity')} />
                        </SimpleItem>
                        
                        <Item>
                          <SelectBox
                            dataField='QuantityUnit'
                            dataSource={quantityUnitData}
                            displayExpr="Name"
                            valueExpr="Key"
                            defaultValue={quantityUnitData[0].Key}
                            onValueChanged={setQuantityUnit}
                          />
                          <Label text={formatMessage('QuantityUnit')} />
                        </Item>

                        <Item dataField="WerkstoffNr" editorType="dxSelectBox" editorOptions={alloysEditorOptions}>
                          <Label text={formatMessage('Alloy')} />
                        </Item>

                        <Item colSpan={1} dataField="Finish" editorType="dxSelectBox" editorOptions={finishEditorOptions}>
                              <Label text={formatMessage('Finish')}  />
                            </Item>
                        
                            <Item colSpan={2} dataField="Cut" editorType="dxTextBox" editorOptions={null}>
                              <Label text={formatMessage('Cut')} />
                            </Item>
                            <Item  colSpan={3} dataField="Info" editorType="dxTextArea" editorOptions={null}>
                              <Label text={formatMessage('Information')}  />
                            </Item>


                          </GroupItem>
                    </GroupItem>
                  </Form>
                  <DataGrid
                    dataSource={dataStore}
                    showBorders={true}
                    columnAutoWidth={true}
                    ref={dataGrid}
                    height={'calc(100vh - 500px)'}
                  >
                      <Sorting mode="single" />
                      <HeaderFilter visible={true} />
                      <FilterRow visible={true} />
                      <FilterPanel visible={true} />
                      {/*  
                      <Column
                      dataType="string"
                      dataField="ArticleName"
                      allowHeaderFiltering={false}
                      caption={formatMessage('ItemModel')}
                      >
                      </Column>
                      */}
                      <Column
                      dataType="string"
                      dataField="Measurements"
                      allowHeaderFiltering={false}
                      caption={formatMessage('Measurements')}
                      visible={false}
                      >
                      </Column> 
                      
{currentIoColumns.map(coordinate => (
                           <Column
                           dataType="number"
                           dataField={coordinate.ColumnName}
                           caption={coordinate.ColumnDescription}
                           allowHeaderFiltering={false}
                           allowSearch={false}
                           allowEditing={false}
                     >

                     </Column>
))} 
                      <Column
                      dataType="string"
                      dataField="WerkstoffNr"
                      allowHeaderFiltering={true}
                      caption={formatMessage('Alloy')}
                      >
                      </Column>  
                      <Column
                      dataType="number"
                      dataField="Weight"
                      alignment={'left'}
                      allowHeaderFiltering={false}
                      caption={formatMessage('Weight') + ' *'}
                      >
                        <Format
                          type="fixedPoint"
                          precision={2}
                        />
                      </Column> 
                      <Column
                      dataType="string"
                      dataField="Availability"
                      allowHeaderFiltering={true}
                      caption={formatMessage('Stock') + ' **'}
                      cellRender={stockSign}
                      >
                        <HeaderFilter dataSource={StockHeaderFilters} />
                      </Column>  
                      {/*  
                      <Column
                      dataType="boolean"
                      dataField="Catalog"
                      allowHeaderFiltering={true}
                      caption={formatMessage('Catalog')}
                      alignment={'left'}
                      >
                      </Column>
                      */}

                      <Column 
                      type="buttons"
                      >
                        <Button
                          hint={formatMessage('add')}
                          icon="plus"
                          visible={true}
                          disabled={false}
                          onClick={addToQuotationButtonOptions}
                        />
                      </Column>

                      <Scrolling mode="virtual" columnRenderingMode="virtual" />
                  </DataGrid>
                    </div>
                  </div>
              </Item>
              <Item title={formatMessage('ItemSelection') + ' (' + NewQuotationItemscounter + ')'} icon="bulletlist">

              <DataGrid
                dataSource={NewQuotationItemsDataStore}
                showBorders={true}
                onSaved={deleteItem}
                columnAutoWidth={true}
                height={'calc(100vh - 300px)'}
                onCellClick={toggleMasterRowSummary}
                ref={dataGridNewQuotation}
              >
                  <HeaderFilter visible={true} />
                  <FilterRow visible={true} />
                  <FilterPanel visible={true} />
                  <Editing
                            mode="row"
                            allowUpdating={false}
                            allowAdding={false}
                            allowDeleting={true} />

                      <Column
                      dataType="string"
                      dataField="Quantity"
                      allowHeaderFiltering={false}
                      caption={formatMessage('Quantity')}
                      >
                      </Column>
                      <Column
                      dataType="string"
                      dataField="QuantityUnit"
                      cellTemplate={cellTemplateQuantityUnit}
                      allowHeaderFiltering={false}
                      caption={formatMessage('QuantityUnit')}
                      >
                      </Column>
                      <Column
                      dataType="string"
                      dataField="ArticleName"
                      allowHeaderFiltering={false}
                      caption={formatMessage('ItemModel')}
                      >
                      </Column>
                      <Column
                      dataType="string"
                      dataField="Measurements"
                      allowHeaderFiltering={false}
                      caption={formatMessage('Measurements')}
                      
                      >
                      </Column>   
                      <Column
                      dataType="string"
                      dataField="WerkstoffNr"
                      allowHeaderFiltering={true}
                      caption={formatMessage('Alloy')}
                      >
                      </Column>  
                      <Column
                      dataType="number"
                      dataField="Weight"
                      alignment={'left'}
                      allowHeaderFiltering={false}
                      caption={formatMessage('Weight') + ' *'}
                      >
                        <Format
                          type="fixedPoint"
                          precision={2}
                        />
                      </Column> 
                      <Column
                      dataType="string"
                      dataField="Availability"
                      allowHeaderFiltering={true}
                      caption={formatMessage('Stock') + ' **'}
                      cellRender={stockSign}
                      >
                        <HeaderFilter dataSource={StockHeaderFilters} />
                      </Column>   

                      <Column
                      dataType="string"
                      dataField="Finish"
                      allowHeaderFiltering={true}
                      caption={formatMessage('Finish')}
                      alignment={'left'}
                      >
                      </Column>  
                      <Column
                      dataType="string"
                      dataField="Cut"
                      allowHeaderFiltering={true}
                      caption={formatMessage('Cut')}
                      alignment={'left'}
                      >
                      </Column>   
                      <Column
                      dataType="string"
                      dataField="Info"
                      allowHeaderFiltering={true}
                      caption={formatMessage('Information')}
                      alignment={'left'}
                      >
                      </Column>  
                  <Scrolling mode="infinite" columnRenderingMode="virtual" />

              </DataGrid>



              </Item>
            </TabPanel>
            <p>
              * {formatMessage('TheoreticalWeight')}, ** {formatMessage('PriorSaleReserved')}, {formatMessage('SubjectToPrintingErrors')}
            </p>

        </div>
              )}
    </div>
  );
}

export default QuotationNew;



