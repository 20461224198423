import React from 'react';
import { locale, loadMessages, formatMessage } from 'devextreme/localization';

const ItemModelButtons = ({changeItemModelSelection, itemQuery, itemModelStore}) => {

  return (
<>
{itemModelStore.map(itemModel => (
    <>
    <button onClick={changeItemModelSelection} name={itemModel.ModelName} class="itemModelButton" title={formatMessage(itemModel.ModelName)} value={itemModel.ID_SQL} className={itemModel.ID_SQL == 12 ? ('itemModelButton active') : ('itemModelButton ')} >
{itemModel.ID_SQL == 1 ? (
<>
<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="-12 -10 48 48">
  <path id="C_PROFIL" data-name="C PROFIL" class="cls-1" d="M2502.6,30.695h-22.093V1.74h22.084v9.706h-4V5.74h-14.084V26.695H2498.6v-7h4Z" transform="translate(-2480.507 -1.74)"/>
</svg>
</>
) : (<></>)}

{itemModel.ID_SQL == 2 ? (
<>
<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="-7 -10 48 48">
  <path id="DREIECKROHR" class="cls-1" d="M16.5,8.089,6.878,25H26.122L16.5,8.089M16.5,0,33,29H0Z" transform="translate(0 0)"/>
</svg>

</>
) : (<></>)}

{itemModel.ID_SQL == 3 ? (
<>
<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="-8 -14 48 48">
  <g transform="translate(-8 -12)">
    <rect id="FLACHSTANGE" width="32" height="15" class="cls-1" transform="translate(8 21)" fill="#242424"/>
    <path id="FLACHSTANGE_-_Kontur" class="cls-1" data-name="FLACHSTANGE - Kontur" d="M3,3v9H29V3H3M0,0H32V15H0Z" transform="translate(8 21)" />
  </g>
</svg>

</>
) : (<></>)}

{itemModel.ID_SQL == 4 ? (
<>
<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="-8 -14 48 48">
  <path id="FORMROHR" class="cls-1" d="M4,4V20H28V4H4M0,0H32V24H0Z"/>
</svg>

</>
) : (<></>)}

{itemModel.ID_SQL == 5 ? (
<>
<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="-8 -14 48 48">
  <path id="FORMROHR_MIT_RADIUS" data-name="FORMROHR MIT RADIUS" class="cls-1" d="M5,4A1,1,0,0,0,4,5V19a1,1,0,0,0,1,1H27a1,1,0,0,0,1-1V5a1,1,0,0,0-1-1H5M5,0H27a5,5,0,0,1,5,5V19a5,5,0,0,1-5,5H5a5,5,0,0,1-5-5V5A5,5,0,0,1,5,0Z"/>
</svg>

</>
) : (<></>)}

{itemModel.ID_SQL == 7 ? (
<>
<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="-8 -14 48 48">
  <g id="FORMROHR_MIT_STEG" data-name="FORMROHR MIT STEG" transform="translate(-8 -12)">
    <path id="FORMROHR_MIT_STEG-2" data-name="FORMROHR MIT STEG" class="cls-1" d="M4,4V20H28V4H4M0,0H32V24H0Z" transform="translate(8 12)"/>
    <path id="Pfad_8972" data-name="Pfad 8972" class="cls-1" d="M2497.514,30h-4V6h4Z" transform="translate(-2471.514 6)"/>
  </g>
</svg>

</>
) : (<></>)}

{itemModel.ID_SQL == 8 ? (
<>
<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="-7 -11 48 48">
  <g id="H_PROFIL" data-name="H PROFIL" transform="translate(-8 -8.5)">
    <path id="Linie_541" data-name="Linie 541" class="cls-1" d="M32,2H0V-2H32Z" transform="translate(8 12)"/>
    <path id="Linie_542" data-name="Linie 542" class="cls-1" d="M32,2H0V-2H32Z" transform="translate(8 36)"/>
    <path id="Linie_543" data-name="Linie 543" class="cls-1" d="M2,20H-2V0H2Z" transform="translate(24 14)"/>
  </g>
</svg>

</>
) : (<></>)}

{itemModel.ID_SQL == 9 ? (
<>
<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="-5 -18 48 48">
  <path id="HUT_PROFIL" data-name="HUT PROFIL" class="cls-1" d="M2511.205,33.635h-12.7v-10H2487.9v10h-13.09v-4h9.09v-10H2502.5v10h8.7Z" transform="translate(-2474.805 -19.633)"/>
</svg>

</>
) : (<></>)}

{itemModel.ID_SQL == 11 ? (
<>
<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="-8 -14 48 48">
  <path id="OVALROHR" class="cls-1" d="M12,4a8,8,0,0,0,0,16h8A8,8,0,0,0,20,4H12m0-4h8a12,12,0,0,1,0,24H12A12,12,0,0,1,12,0Z"/>
</svg>
</>
) : (<></>)}

{itemModel.ID_SQL == 12 ? (
<>
<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="-7 -11 48 48">
  <g transform="translate(-8 -8)">
    <circle id="RUNDROHR" fill="transparent" cx="16" cy="16" r="16" transform="translate(8 8)"/>
    <path id="RUNDROHR_-_Kontur" data-name="RUNDROHR - Kontur" class="cls-1" d="M16,4a12,12,0,1,0,8.485,3.515A11.921,11.921,0,0,0,16,4m0-4A16,16,0,1,1,0,16,16,16,0,0,1,16,0Z" transform="translate(8 8)"/>
  </g>
</svg>
</>
) : (<></>)}

{itemModel.ID_SQL == 13 ? (
<>
<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="-7 -11 48 48">
  <g transform="translate(-8 -8)">
    <circle id="RUNDSTANGE" class="cls-1" cx="16" cy="16" r="16" transform="translate(8 8)"/>
    <path id="RUNDSTANGE_-_Kontur" data-name="RUNDSTANGE - Kontur" class="cls-1" d="M16,3A13,13,0,0,0,6.808,25.192,13,13,0,0,0,25.192,6.808,12.915,12.915,0,0,0,16,3m0-3A16,16,0,1,1,0,16,16,16,0,0,1,16,0Z" transform="translate(8 8)"/>
  </g>
</svg>
</>
) : (<></>)}

{itemModel.ID_SQL == 14 ? (
<>
<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="-7 -8 48 48">
  <g transform="translate(-6.925 -4.401)">
    <path id="SECHSKANTSTANGE" class="cls-1" d="M308.251,12880.67l15.529-8.979,15.444,8.979v17.91l-15.444,8.841-15.69-9.088Z" transform="translate(-299.657 -12865.557)"/>
    <path id="SECHSKANTSTANGE_-_Kontur" data-name="SECHSKANTSTANGE - Kontur" class="cls-1" d="M323.782,12869.958l.752.437,16.19,9.412v19.644l-.755.432-16.194,9.27-.747-.433-16.446-9.525.176-19.394Zm13.942,11.575-13.946-8.108-14.035,8.115-.145,15.933,14.186,8.218,13.94-7.98Z" transform="translate(-299.657 -12865.557)"/>
  </g>
</svg>
</>
) : (<></>)}

{itemModel.ID_SQL == 15 ? (
<>
<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="-7 -11 48 48">
  <g id="T_PROFIL" data-name="T PROFIL" transform="translate(-8 -9)">
    <path id="Linie_544" data-name="Linie 544" class="cls-1" d="M32,2H0V-2H32Z" transform="translate(8 12.5)"/>
    <path id="Linie_545" data-name="Linie 545" class="cls-1" d="M2,25H-2V0H2Z" transform="translate(24 12.5)"/>
  </g>
</svg>
</>
) : (<></>)}

{itemModel.ID_SQL == 16 ? (
<>
<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="-7 -11 48 48">
  <g id="T_PROFIL_MIT_RADIUS" data-name="T PROFIL MIT RADIUS" transform="translate(-7.5 -9)">
    <path id="Pfad_8973" data-name="Pfad 8973" class="cls-1" d="M30.969,2H3A2,2,0,0,1,1,0,2,2,0,0,1,3-2H30.969a2,2,0,0,1,2,2A2,2,0,0,1,30.969,2Z" transform="translate(7.002 12.5)"/>
    <path id="Linie_545" data-name="Linie 545" class="cls-1" d="M0,25a2,2,0,0,1-2-2V0A2,2,0,0,1,0-2,2,2,0,0,1,2,0V23A2,2,0,0,1,0,25Z" transform="translate(24 12.5)"/>
  </g>
</svg>
</>
) : (<></>)}

{itemModel.ID_SQL == 17 ? (
<>
<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="-11 -11 48 48">
  <path id="U_PROFIL" data-name="U PROFIL" class="cls-1" d="M2508.555,32.793H2482V6.429h4V28.793h18.555V6.063h4Z" transform="translate(-2482 -6.063)"/>
</svg>
</>
) : (<></>)}

{itemModel.ID_SQL == 18 ? (
<>
<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="-11 -11 48 48">
  <path id="U_PROFIL_MIT_RADIUS" data-name="U PROFIL MIT RADIUS" class="cls-1" d="M2506.555,30.415H2484a2,2,0,0,1-2-2V6.394a2,2,0,0,1,4,0V26.415h18.555V6.063a2,2,0,0,1,4,0V28.415A2,2,0,0,1,2506.555,30.415Z" transform="translate(-2482 -4.063)"/>
</svg>
</>
) : (<></>)}

{itemModel.ID_SQL == 19 ? (
<>
<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="-11 -11 48 48">
  <path id="U_PROFIL_UNGLEICHSCHENKELIG" data-name="U PROFIL UNGLEICHSCHENKELIG" class="cls-1" d="M2508.555,32.793H2482V6.429h4V28.793h18.555v-10.3h4Z" transform="translate(-2482 -6.429)"/>
</svg>
</>
) : (<></>)}

{itemModel.ID_SQL == 20 ? (
<>
<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="-13 -11 48 48">
  <path id="WINKEL" class="cls-1" d="M2500.346,32H2478V6h4V28h18.346Z" transform="translate(-2478 -6)"/>
</svg>
</>
) : (<></>)}

{itemModel.ID_SQL == 21 ? (
<>
<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="-13 -11 48 48">
  <path id="WINKEL_MIT_RADIUS" data-name="WINKEL MIT RADIUS" class="cls-1" d="M2498.329,29.941H2480a2,2,0,0,1-2-2V6a2,2,0,0,1,4,0V25.941h16.329a2,2,0,0,1,0,4Z" transform="translate(-2478 -4)"/>
</svg>
</>
) : (<></>)}

{itemModel.ID_SQL == 22 ? (
<>
<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="-7 -11 48 48">
  <path id="Z_PROFIL" data-name="Z PROFIL" class="cls-1" d="M2511.847,32.8h-17.967V7.156h-13.97v-4h17.97V28.8h13.967Z" transform="translate(-2479.91 -3.156)"/>
</svg>
</>
) : (<></>)}

{itemModel.ID_SQL == 23 ? (
<>
<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="-7 -16 48 48">
  <path id="Z_PROFIL_135" data-name="Z PROFIL 135" class="cls-1" d="M2511.347,26.963h-10.239l-13.186-14.034h-8.513v-4h10.243l13.186,14.034h8.508Z" transform="translate(-2479.41 -8.929)"/>
</svg>
</>
) : (<></>)}

{itemModel.ID_SQL == 24 ? (
<>
<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="-7 -11 48 48">
  <path id="Z_PROFIL_MIT_RADIUS" data-name="Z PROFIL MIT RADIUS" class="cls-1" d="M2507.02,32.8h-13.553a2,2,0,0,1-2-2V7.156H2479.91a2,2,0,0,1,0-4h13.556a2,2,0,0,1,2,2V28.8h11.553a2,2,0,0,1,0,4Z" transform="translate(-2477.91 -3.156)"/>
</svg>
</>
) : (<></>)}

{itemModel.ID_SQL == 25 ? (
<>
<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 -2 48 48">
  <g id="Profil_Icons" data-name="Profil Icons" clip-path="url(#clip-Profil_Icons)">
    <path id="FORMROHR_MIT_SCHRAUBKANAL-2" data-name="FORMROHR MIT SCHRAUBKANAL" class="cls-1" d="M4,4V20H28V4H4M0,0H32V24H0Z" transform="translate(8 12)"/>
    <path id="Linie_548" data-name="Linie 548" class="cls-1" d="M2,4H-2V0H2Z" transform="translate(18 28)"/>
    <path id="Linie_549" data-name="Linie 549" class="cls-1" d="M2,4H-2V0H2Z" transform="translate(30 28)"/>
  </g>
  </svg>
</>
) : (<></>)}

{itemModel.ID_SQL == 26 ? (
<>
<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="3 -2 48 48">
  <g id="Profil_Icons" data-name="Profil Icons" clip-path="url(#clip-Profil_Icons)">
    <g id="FALTSTEGPROFIL_1_STEG" data-name="FALTSTEGPROFIL 1 STEG" transform="translate(48 4) rotate(90)">
      <path id="Rechteck_24307" data-name="Rechteck 24307" d="M4,4V20h8V4H4M0,0H16V24H0Z" transform="translate(12 12)" class="cls-1" />
      <path id="Linie_550" data-name="Linie 550" d="M2,32H-2V0H2Z" transform="translate(26 4)" class="cls-1" />
    </g>
  </g>
  </svg>
</>
) : (<></>)}

{itemModel.ID_SQL == 27 ? (
<>
<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 -2 48 48">
  <g id="Profil_Icons" data-name="Profil Icons" clip-path="url(#clip-Profil_Icons)">
    <g id="FALTSTEGPROFIL_2_STEG" data-name="FALTSTEGPROFIL 2 STEG" transform="translate(48 4) rotate(90)">
      <path id="Rechteck_24307" data-name="Rechteck 24307" d="M4,4V20h8V4H4M0,0H16V24H0Z" transform="translate(12 12)" class="cls-1"/>
      <path id="Linie_550" data-name="Linie 550" d="M2,40H-2V0H2Z" transform="translate(26 4)" class="cls-1"/>
    </g>
  </g>
  </svg>
</>
) : (<></>)}

{itemModel.ID_SQL == 28 ? (
<>
<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 -2 48 48">
  <g id="FORMROHR_MIT_2_STEG" data-name="FORMROHR MIT 2 STEG">
    <path id="FORMROHR_MIT_STEG" data-name="FORMROHR MIT STEG" class="cls-1" d="M4,4V20H28V4H4M0,0H32V24H0Z" transform="translate(8 12)"/>
    <path id="Pfad_8972" data-name="Pfad 8972" class="cls-1" d="M2497.514,30h-4V6h4Z" transform="translate(-2475.514 6)"/>
    <path id="Pfad_8976" data-name="Pfad 8976" class="cls-1" d="M2497.514,30h-4V6h4Z" transform="translate(-2467.514 6)"/>
    </g>
  </svg>
</>
) : (<></>)}

{itemModel.ID_SQL == 29 ? (
<>
<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 -2 48 48">
<g id="Profil_Icons" data-name="Profil Icons" clip-path="url(#clip-Profil_Icons)">
    <g id="H_PROFIL_MIT_RADIUS" data-name="H PROFIL MIT RADIUS">
      <path id="Linie_541" data-name="Linie 541" class="cls-1" d="M28,2H0A2,2,0,0,1-2,0,2,2,0,0,1,0-2H28a2,2,0,0,1,2,2A2,2,0,0,1,28,2Z" transform="translate(10 12)"/>
      <path id="Linie_542" data-name="Linie 542" class="cls-1" d="M28,2H0A2,2,0,0,1-2,0,2,2,0,0,1,0-2H28a2,2,0,0,1,2,2A2,2,0,0,1,28,2Z" transform="translate(10 36)"/>
      <path id="Linie_543" data-name="Linie 543" class="cls-1" d="M0,22a2,2,0,0,1-2-2V0A2,2,0,0,1,0-2,2,2,0,0,1,2,0V20A2,2,0,0,1,0,22Z" transform="translate(24 14)"/>
    </g>
  </g>
</svg>
</>
) : (<></>)}

{itemModel.ID_SQL == 30 ? (
<>
<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="-4 -2 48 48">
  <g id="Profil_Icons" data-name="Profil Icons" clip-path="url(#clip-Profil_Icons)">
    <rect id="VIREKANTSTANGE" class="cls-1" width="24" height="24" transform="translate(8 12)"/>
  </g>
  </svg>
</>
) : (<></>)}

    </button>
    </>                      
))}
</>
  );
}

export default ItemModelButtons;