import React, { useEffect, useState, useRef } from 'react';
import { locale, loadMessages, formatMessage } from 'devextreme/localization';

import SelectBox from 'devextreme-react/select-box';
import notify from 'devextreme/ui/notify';

import Form, {
  ButtonItem,
  GroupItem,
  SimpleItem,
  Label,
  CompareRule,
  EmailRule,
  PatternRule,
  RangeRule,
  RequiredRule,
  StringLengthRule,
  AsyncRule,
  CustomRule,
  FormTypes,
  Item
} from 'devextreme-react/form';

const MasterQuotationItemLookup = (props) => {
  console.log ('MasterQuotationItemLookup', props);

  const ID_SQL = props.data.data.ID_SQL;
  const rowData = props.data;
  const itemOptions = useRef(null);
  const itemData = {};

  const quantityUnitData = [
    {Key: 'BESTAND_IN_KG', Name: formatMessage('BESTAND_IN_KG')}, 
    {Key: 'BESTAND_IN_STG', Name: formatMessage('BESTAND_IN_STG')}, 
    {Key: 'VERFUEGBARE_MENGE', Name: formatMessage('VERFUEGBARE_MENGE')}
  ];

  let defaultQuantityUnit = quantityUnitData[0].Key;
  if (props.data.data.QuantityUnit) {
    defaultQuantityUnit = props.data.data.QuantityUnit;
  }

  let defaultFinish = 'Keine';
  if (props.data.data.Finish) {
    defaultFinish = props.data.data.Finish;
  }
  
  const finishEditorOptions = { 
    items:[
      'Keine', 'Pulverbeschichtet', 'Eloxal', 'Sonstiges (siehe Notiz)'
    ], 
    value: defaultFinish,
    searchEnabled: false 
  };

  let NewQuotation = JSON.parse(localStorage.getItem('NewQuotation'));
  if (!NewQuotation) NewQuotation = {};


  let isAdded = false;
  NewQuotation.QuotationItems.forEach(item => {
    if (item.ItemId == ID_SQL) isAdded = true;
  });


  let addVisible = false;
  if (props.data.data.QuotationId != undefined) {
    // existing quotation
    addVisible = (props.data.data.QuotationId) ? false : true;
  }
  else {
    // new quotation
    addVisible = (isAdded) ? false : true;
  } 


  const addToQuotationButtonOptions = {
    text: formatMessage('add'),
    type: 'default',
    icon: 'fa-sharp fa-solid fa-plus',
    useSubmitBehavior: false,
    visible: addVisible,
    onClick: function(e) {
      if (!NewQuotation.QuotationItems)  NewQuotation.QuotationItems = [];
      itemOptions.current.props.formData.ItemId = ID_SQL;
      console.log (itemOptions.current.props.formData);
      let alreadyExist = false;
      NewQuotation.QuotationItems.forEach(item => {
        if (item.ID_SQL == ID_SQL) {
          alreadyExist = true;
        }
      });
      if (!alreadyExist) {
        NewQuotation.QuotationItems.push(itemOptions.current.props.formData);
        localStorage.setItem('NewQuotation', JSON.stringify(NewQuotation));
      }
      notify(
        {
          message: formatMessage('addSuccess'),
          position: {
            my: 'center center',
            at: 'center center',
          },
        },
        'success',
        3000,
      );
    }   
  }

  function QuotationItemDataChanged (e) {
    let currentNewQuotation = JSON.parse(localStorage.getItem('NewQuotation'));
    currentNewQuotation.QuotationItems.forEach((item, key) => {
      if (item.ID_SQL === ID_SQL) {
        item[e.dataField] = e.value;      
      }
    });
    localStorage.setItem('NewQuotation', JSON.stringify(currentNewQuotation));
  }

  function setQuantityUnit (evt){
    itemOptions.current.props.formData.QuantityUnit = evt.value;
  }

  return (
    <div>
        <Form
          ref={itemOptions}
          formData={rowData.data}
          onFieldDataChanged={QuotationItemDataChanged}
        >
          <GroupItem colCount={11}>
            <Item colSpan={2} >
                <SelectBox
                    dataField='QuantityUnit'
                    dataSource={quantityUnitData}
                    displayExpr="Name"
                    valueExpr="Key"
                    defaultValue={defaultQuantityUnit}
                    onValueChanged={setQuantityUnit}
                />
                <Label text={formatMessage('QuantityUnit')} location="left" />
              </Item>
              <SimpleItem colSpan={1} 
                dataField='Quantity'
                editorType="dxTextBox"
              >
                <Label text={formatMessage('Quantity')} location="left" />
              </SimpleItem>
              <Item colSpan={2} dataField="Finish" editorType="dxSelectBox" editorOptions={finishEditorOptions}>
                <Label text={formatMessage('Finish')} location="left" />
              </Item>
              <Item colSpan={2}  dataField="Cut" editorType="dxTextBox" editorOptions={null}>
                <Label text={formatMessage('Cut')} location="left" />
              </Item>
              <Item colSpan={3}  dataField="Info" editorType="dxTextBox" editorOptions={null}>
                <Label text={formatMessage('Information')} location="left" />
              </Item>
              <ButtonItem colSpan={1}  buttonOptions={addToQuotationButtonOptions} />
          </GroupItem>
        </Form>
    </div>
  );
};
export default MasterQuotationItemLookup;