const locales = [
  {
    Name: 'English',
    Value: 'en',
  },
  {
    Name: 'Deutsch',
    Value: 'de',
  }
];
const dictionary = {
  en: {
    Home: 'Home',
    Quotations: 'Quotations',
    QuotationsOpen: 'Open Quotations',
    QuotationNew: 'New Quotation',
    QuotationsArchive: 'Archive',
    Settings: 'Settings',
    User: 'User',

    Examples: 'Examples',
    Number: 'Number',
    Contact: 'Contact',
    Company: 'Company',
    Amount: 'Amount',
    PaymentDate: 'Payment Date',
    Name: 'Name',
    Rating: 'Rating',
    VendorId: 'Number',
    Country: 'Country',
    City: 'City',
    PostCode: 'ZIP',
    Note: 'Note',
    Vendors: 'Suppliers',
    VendorName: 'Supplier name',
    VendorId: 'Id',
    SearchVendor: 'search Supplier',
    CreateVendor: 'save new Supplier',
    NewVendor: 'add new Supplier',
    VendorProduction: 'Extrusion',
    VendorRetail: 'Retail',
    VendorInternet: 'Internet',

    add: 'add',

    Alloys: 'Alloys',
    VendorType: 'Type',
    Presses: 'Presses',
    LastCheckDate: 'Review date',
    LastCheckFrom: 'Review from',
    Retail: 'Retail',
    Production: 'Production',

    Quotation: 'Quotation',
    QuotationNo: 'Number',
    QuotationDate: 'Datum',
    QuotationStateDescription: 'State',

    Quantity: 'Quantity',
    QuantityUnit: 'Unit',
    ArticleName: 'Article',
    ItemNumber: 'Number',
    ToolNumber: 'Tool',
    Stock: 'Stock',
    Catalog: 'Catalog',
    Alloy: 'Alloy',
    Finish: 'Finish',

    Available: 'Available',
    Unit: 'Unit',
    AvailableInkg: 'Available in kg',
    Availableinpcs: 'Available in pcs',
    Kg_unit: 'kg/unit',
    Length: 'Length',


    Article: 'Article',

    BESTAND_IN_STG: 'pcs',
    BESTAND_IN_KG: 'kg',
    VERFUEGBARE_MENGE: 'm',
    
    ItemModel: 'Profile',
    C_PROFIL: 'C-profile',
    DREIECKROHR: 'Triangle tube',
    FLACHSTANGE: 'Flat bar',
    FORMROHR: 'Rectangular tube',
    FORMROHR_MIT_RADIUS: 'Rectangular tube with radius',
    FORMROHR_STEG: 'Rectangular tube with middlebar',
    FORMROHR_MIT_STEG: 'Rectangular tube with middlebar',
    FORMROHR_MIT_ZWEI_STEG: 'Rectangular tube with two middlebars',
    FORMROHR_MIT_SCHRAUBKANAL: 'Rectangular tube with screw channel',
    H_PROFIL: 'H-section',
    H_PROFIL_MIT_RADIUS: 'H-section with radius',
    HUT_PROFIL: 'Omega-Profile',
    OVALROHR: 'Oval tube',
    RUNDROHR: 'Round tube',
    RUNDSTANGE: 'Round bar',
    SECHSKANTSTANGE: 'Hexagonal bar',
    T_PROFIL: 'T-section',
    T_PROFIL_MIT_RADIUS: 'T-section with radius',
    U_PROFIL: 'U-profile',
    U_PROFIL_MIT_RADIUS: 'U-profile with radius',
    U_PROFIL_UNGLEICHSCHENKELIG: 'U-profile unequal',
    WINKEL: 'Angle',
    WINKEL_MIT_RADIUS: 'Angle with radius',
    Z_PROFIL: 'Z-section',
    Z_PROFIL_135: 'Z-section 135°',
    Z_PROFIL_MIT_RADIUS: 'Z-section with radius',
    FALTSTEGPROFIL_EIN_STEG: 'Folding bar profile with one bar',
    FALTSTEGPROFIL_ZWEI_STEG: 'Folding bar profile with two bars',
    VIERKANTSTANGE: 'Square bar',

    EmailSalutation: 'Dear',
    EmailIntro: 'We would like to ask you for an offer for the following articles\n',
    EmailOutro: 'If you have any questions, please contact me immediately.\n\n Many thanks in advance!\n\n Best regards, {name}',


    SettingsUserName: 'Name',
    SettingsUserEmail: 'E-Mail',
    SettingsUserAreas: 'Salesarea',
    SettingsUserRole: 'Role',
    SettingsUserShortcode: 'Code',

    RamsauerId: 'Supplier No.',

    Presses: 'Press',
    PressName: 'Press name',
    PressSize: 'Press size',
    Water: 'Water',
    SprayWater: 'Spray Water',
    Air: 'Air',


    Measurements: 'Dimensions',
    VendorCatalogNo: 'Die number',
    BLECHAARTNR: 'Article number',
    WerkstoffNr: 'Alloy',
    Weight: 'Weight kg/m',

    SaveCatalogReview: 'finalise review',
    StartCatalogReview: 'start review',

    CatalogHeader: 'Product range',

    Contact: 'Customer information',

    ItemSearch: 'Profile search',
    ItemSelection: 'Summary',   
    
    Cut: 'Cut',
    SelectedVendors: 'Selected Suppliers',

    Firstname: 'Firstname',
    Surname: 'Surname',
    Salutation: 'Salutation',
    Title: 'Title',
    Phone: 'Phone',
    Email: 'Email',
    Street: 'Street',
    VAT: 'VAT',
    Information: 'Information',

    InformationVerlauf: 'History',
    InformationNeu: 'New',
    Search: 'Search',
    addSuccess: 'successfully added',
    saveSuccess: 'successfully saved',
    GOG: 'pulled/pressed',
    Surcharge: 'Surcharge',
    CompanyNo: 'Customer number',
    InStock: 'in stock',
    OutOfStock: 'out of stock',
    PartlyInStock: 'partly in stock',
    OnRequest: 'on request',

    TheoreticalWeight: 'theoretical weight kg/m',
    PriorSaleReserved: 'prior sale reserved',
    SubjectToPrintingErrors: 'Subject to printing errors',
    noQuantity: 'please specify quantity',
    GOG: 'extruded/drawn',
    GOG_0: 'extruded',
    GOG_1: 'drawn',
  },
  de: {
    Home: 'Start',
    Quotations: 'Anfragen',
    QuotationsOpen: 'Offene Anfragen',
    QuotationNew: 'Neue Anfrage',
    QuotationsArchive: 'Archiv',
    Settings: 'Einstellungen',
    User: 'Benutzer',

    Examples: 'Beispiele',
    Number: 'Nummer',
    Contact: 'Ansprechpartner',
    Company: 'Firma',
    Amount: 'Betrag',
    PaymentDate: 'Zahlungsdatum',
    Name: 'Name',
    Rating: 'Bewertung',
    VendorId: 'Nummer',
    Country: 'Land',
    City: 'Ort',
    PostCode: 'PLZ',
    Note: 'Notiz',
    Vendors: 'Lieferanten',
    VendorName: 'Lieferantenname',
    VendorId: 'Id',
    Alloys: 'Legierungen',
    VendorType: 'Typ',
    SearchVendor: 'Lieferant suchen',
    CreateVendor: 'Lieferant speichern',
    NewVendor: 'Lieferant hinzufügen',
    VendorProduction: 'Presswerk',
    VendorRetail: 'Handel',
    VendorInternet: 'Internet',

    Presses: 'Pressen',
    LastCheckDate: 'Prüfung am',
    LastCheckFrom: 'Prüfung von',
    Retail: 'Handel',
    Production: 'Presswerk',

    Quotation: 'Anfrage',
    QuotationNo: 'Nummer',
    QuotationDate: 'Datum',
    QuotationStateDescription: 'Status',

    Quantity: 'Menge',
    QuantityUnit: 'Einheit',
    ArticleName: 'Artikel',
    ItemNumber: 'Artikelnummer',
    ToolNumber: 'Werkzeugnummer',
    Stock: 'Lager',
    Catalog: 'Katalog',
    Alloy: 'Legierung',
    Finish: 'Oberfläche',

    Available: 'Verfügbar',
    Unit: 'Einheit',
    AvailableInkg: 'Verfügbar In kg',
    Availableinpcs: 'Verfügbar in Stangen',
    Kg_unit: 'kg/Einheit',
    Length: 'Länge',

    Article: 'Artikel',
    BESTAND_IN_STG: 'Stg',
    BESTAND_IN_KG: 'kg',
    VERFUEGBARE_MENGE: 'm',

    ItemModel: 'Profil',
    C_PROFIL: 'C-Profil',
    DREIECKROHR: 'Dreieck-Formrohr',
    FLACHSTANGE: 'Flachstange',
    FORMROHR: 'Formrohr',
    FORMROHR_MIT_RADIUS: 'Formrohr mit Radius',
    FORMROHR_STEG: 'Formrohr mit Steg',
    FORMROHR_MIT_STEG: 'Formrohr mit Steg',
    FORMROHR_MIT_ZWEI_STEG: 'Formrohr mit 2x Steg',
    FORMROHR_MIT_SCHRAUBKANAL: 'Formrohr mit Schraubkanal',
    H_PROFIL: 'H-Profil',
    H_PROFIL_MIT_RADIUS: 'H-Profil mit Radius',
    HUT_PROFIL: 'Omega-Profil',
    OVALROHR: 'Ovalrohr',
    RUNDROHR: 'Rundrohr',
    RUNDSTANGE: 'Rundstange',
    SECHSKANTSTANGE: 'Sechskantstange',
    T_PROFIL: 'T-Profil',
    T_PROFIL_MIT_RADIUS: 'T-Profil mit Radius',
    U_PROFIL: 'U-Profil',
    U_PROFIL_MIT_RADIUS: 'U-Profil mit Radius',
    U_PROFIL_UNGLEICHSCHENKELIG: 'U-Profil ungleichschenkelig',
    WINKEL: 'Winkel',
    WINKEL_MIT_RADIUS: 'Winkel mit Radius',
    Z_PROFIL: 'Z-Profil',
    Z_PROFIL_135: 'Z-Profil 135°',
    Z_PROFIL_MIT_RADIUS: 'Z-Profil mit Radius',
    FALTSTEGPROFIL_EIN_STEG: 'Faltstegprofil mit einem Steg',
    FALTSTEGPROFIL_ZWEI_STEG: 'Faltstegprofil mit zwei Stegen',
    VIERKANTSTANGE: 'Vierkantstange',

    EmailSalutation: 'Guten Tag ',
    EmailIntro: 'Wir bitten höfflichst um ein Angebot zu folgenden Artikeln: \n',
    EmailOutro: 'Wenn Sie Fragen haben melden Sie sich bitte umgehend bei mir.\n\n Vielen Dank im Voraus!\n\n Beste Grüße,\n',

    SettingsUserName: 'Name',
    SettingsUserEmail: 'E-Mail',
    SettingsUserAreas: 'Verkaufsgebiete',
    SettingsUserRole: 'Rolle',
    SettingsUserShortcode: 'Kürzel',

    RamsauerId: 'Lieferanten Nr.',

    Presses: 'Pressen',
    PressName: 'Pressenname',
    PressSize: 'Pressengröße',
    Water: 'Wasser',
    SprayWater: 'Sprühwasser',
    Air: 'Luft',

    Measurements: 'Abmessungen',
    VendorCatalogNo: 'Werkzeugnummer',
    BLECHAARTNR: 'Artikelnummer',
    WerkstoffNr: 'Legierung',
    Weight: 'Gewicht kg/m',

    SaveCatalogReview: 'Prüfung abschließen',
    StartCatalogReview: 'Prüfung starten',

    CatalogHeader: 'Lieferprogramm',

    Contact: 'Kundendaten',

    ItemSearch: 'Profilsuche',
    ItemSelection: 'Übersicht',

    add: 'hinzufügen',
    Cut: 'Schnitt',

    SelectedVendors: 'Ausgewählte Lieferanten',

    Firstname: 'Vorname',
    Surname: 'Nachname',
    Salutation: 'Anrede',
    Title: 'Titel',
    Phone: 'Telefon',
    Email: 'E-mail',
    Street: 'Straße',
    VAT: 'UID',


    Information: 'Information',

    InformationVerlauf: 'Verlauf',
    InformationNeu: 'Neu',
    Search: 'Suche',
    addSuccess: 'Erfolgreich hinzugefügt',
    saveSuccess: 'Erfolgreich gespeichert',
    GOG: 'gezogen/gepresst',
    Surcharge: 'Aufpreis',
    CompanyNo: 'Kundennummer',

    InStock: 'lagernd',
    OutOfStock: 'Nicht lagernd',
    PartlyInStock: 'Teilweise lagernd',
    OnRequest: 'Auf Anfrage',
    TheoreticalWeight: 'theoretisches Gewicht kg/m',
    PriorSaleReserved: 'Zwischenverkauf vorbehalten',

    SubjectToPrintingErrors: 'Vorbehaltlich Druckfehler',
    noQuantity: 'Bitte Menge angeben',
    GOG: 'gezogen/gepresst',
    GOG_0: 'gepresst',
    GOG_1: 'gezogen',
  }
};

export default {
  getLocales() {
    return locales;
  },
  getDictionary() {
    return dictionary;
  },
};