import 'devextreme/dist/css/dx.common.css';
//import './themes/generated/theme.base.css';
//import './themes/generated/theme.additional.css';

import './themes/dpk-frontend/dx.generic.dpk-frontend.css';
import './dx-styles.scss';

import React, { useState } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useScreenSizeClass } from './utils/media-query';

import deMessages from 'devextreme/localization/messages/de.json';
import { locale, loadMessages, formatMessage } from 'devextreme/localization';
import localization from './localization/data.js';

import QuotationNew from './pages/quotations/new.js';

if (document.documentElement.lang === 'de-DE') {
  locale(sessionStorage.getItem('locale') || 'de');
}
else if (document.documentElement.lang === 'en-US'){
  locale(sessionStorage.getItem('locale') || 'en');
}
else {
  locale(sessionStorage.getItem('locale') || 'en');
}

loadMessages(deMessages);
loadMessages(localization.getDictionary());

export default function App() {
  const [localeState, setLocaleState] = useState(sessionStorage.getItem('locale') || 'en');
  const locales = localization.getLocales();

  console.log (document.documentElement.lang);

  return (
    <Router path='/'>
      <Routes>
        <Route exact path='/' isPublic='1' element={<QuotationNew/>}/>
      </Routes> 
    </Router>
  );

}
